/* eslint-disable vue/valid-v-model */ /* eslint-disable vue/valid-v-model */
<template>
  <v-container>
    <v-row align="center" justify="center" width="100%">
      <v-col>
        <div style="color:red; font-weight:bold; padding-bottom:30px">
          Nicht zugeordnet
        </div>
      </v-col>
    </v-row>
    <v-card
      v-for="(dragModel, i) in getDragModels(0)"
      :key="i"
      class="d-flex flex-wrap"
      color="white lighten-2"
      flat
      tile
    >
      <draggable
        v-if="$store.state.loginModule.isAdmin"
        :list="dragModel.list"
        group="childGroup"
        @change="onDragChanged"
      >
        <v-chip v-for="item in dragModel.list" :key="item.id" class="ma-2">
          {{ item.title }}
        </v-chip>
      </draggable>
      <v-chip v-for="item in dragModel.list" v-else :key="item.id" class="ma-2">
        {{ item.title }}
      </v-chip>
    </v-card>

    <v-divider class="ma-4" />

    <v-row>
      <v-col v-for="group in Array.from(campData.keys())" :key="group">
        <v-card class="pa-2">
          <v-card-title>{{ group }}</v-card-title>
          <v-card
            v-for="room in campData.get(group)"
            :key="room"
            class="ma-2 pa-4"
          >
            <!-- <v-card-subtitle>Zimmer {{ room }}</v-card-subtitle> -->
            <v-expansion-panels
              ><v-expansion-panel>
                <v-expansion-panel-header
                  >Zimmer {{ room }}</v-expansion-panel-header
                >
                <v-expansion-panel-content>
                  <v-card class="ma-2">
                    <template v-for="bed in 4">
                      <v-col :key="bed">
                        <v-card
                          v-for="(dragModel, i) in getDragModels(room, bed)"
                          :key="i"
                          class="pa-2"
                          outlined
                          tile
                          :color="dragModel.list.length === 0 ? 'green' : 'red'"
                        >
                          {{ getBedName(bed) }}
                          <draggable
                            v-if="$store.state.loginModule.isAdmin"
                            :list="dragModel.list"
                            group="childGroup"
                            @change="onDragChanged"
                          >
                            <v-chip
                              v-for="item in dragModel.list"
                              :key="item.id"
                              class="ma-2"
                            >
                              {{ item.title }}
                            </v-chip>
                          </draggable>
                          <v-chip
                            v-for="item in dragModel.list"
                            v-else
                            :key="item.id"
                            class="ma-2"
                          >
                            {{ item.title }}
                          </v-chip>
                        </v-card>
                      </v-col>
                      <!-- <v-responsive
                  v-if="bed === 2"
                  :key="`width-${bed}`"
                  width="100%"
                ></v-responsive> -->
                    </template>
                  </v-card>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-card>
        </v-card>
      </v-col>
    </v-row>
    <!-- <v-bottom-navigation fixed color="primary">
      <v-btn @click="onSave()">
        <span class="green--text">Speichern</span>
        <v-icon color="green">mdi-content-save-move</v-icon>
      </v-btn>
    </v-bottom-navigation> -->
  </v-container>
</template>

<script>
import draggable from 'vuedraggable';
import {
  CONST_CAMP_DATA,
  CONST_CAMP_BEDS,
  CONST_CAMP_ROOMS
} from '../../plugins/constants/Constants';

import participantsModule from '../../store/modules/participants';

export default {
  components: {
    draggable
  },
  data: () => ({
    campData: CONST_CAMP_DATA,
    availableChildren: [],
    changedChild: '',
    modelArray: [],
    indexMap: null
  }),

  watch: {
    changedChild() {
      // find the child ID in nested array
      const childId = this.changedChild;
      const idx = this.modelArray.findIndex(model => {
        const x = model.findIndex(e => e.id === childId);
        return x !== -1;
      });

      const child = {
        id: this.changedChild
      };

      if (idx === 0) {
        // no group/bed assigned
        child.campData = null;
      } else {
        child.campData = this.indexMap.get(idx);
      }

      this.$store.dispatch('ACT_UPDATE_PARTICIPANT', child);
    }
  },

  created() {
    /* ensure that the store module for login is registered */
    if (!this.$store.hasModule('participantsModule')) {
      this.$store.registerModule('participantsModule', participantsModule);
    }
    // read all available children
    this.readParticipants();

    if (!this.$store.state.participantsModule.participants) {
      return;
    }

    // used to find correct group room bed based ondragList array index
    this.indexMap = this.buildIndexMap();

    // 57 = rooms * bed + unassigned
    const modelArray = [];
    for (let i = 0; i < 57; i += 1) {
      modelArray.push([]);
    }

    this.$store.state.participantsModule.participants.forEach(e => {
      const rObj = {};
      rObj.id = e.id;
      rObj.title = `${e.firstName} ${e.familyName}`;
      // group/room is already assigned
      if (e.campData) {
        const elem = [...this.indexMap.entries()].find(v => {
          if (
            v[1].bed === e.campData.bed &&
            v[1].room === e.campData.room &&
            v[1].group === e.campData.group
          ) {
            return true;
          }
          return false;
        });
        if (elem) {
          modelArray[elem[0]].push(rObj);
        } else {
          console.log(`not found in List:${JSON.stringify(e.campData)}`);
        }
      } else {
        modelArray[0].push(rObj);
      }
    });

    this.modelArray = modelArray;
  },

  methods: {
    debugIt(n) {
      return this.groups.get(n);
    },
    readParticipants() {
      this.$store.dispatch('ACT_GET_PARTICIPANTS').catch(error => {
        this.$root.$info.open(error);
      });
    },

    getBedName(n) {
      return CONST_CAMP_BEDS[n - 1];
    },

    getDragModels(room, bed) {
      if (room === 0) {
        return [{ list: this.modelArray[0] }];
      }
      const indexRoom = CONST_CAMP_ROOMS.findIndex(e => e === room);
      const idx = indexRoom * 4 + bed;
      return [{ list: this.modelArray[idx] }];
    },

    // eslint-disable-next-line no-unused-vars
    onDragChanged(v) {
      if (v.removed) {
        this.changedChild = v.removed.element.id;
      }
    },

    buildIndexMap() {
      const myMap = new Map();
      let i = 0;
      Array.from(CONST_CAMP_DATA.keys()).forEach(group => {
        CONST_CAMP_DATA.get(group).forEach(room => {
          CONST_CAMP_BEDS.forEach(bed => {
            i += 1;
            myMap.set(i, { group, room, bed });
          });
        });
      });
      return myMap;
    }
  }
};
</script>

<style></style>
